import React from "../../../../hooks/reactImport" 
import { useEffect } from "../../../../hooks/stateHooks"
import { styledCompoent, styledkeyframe } from "../../../../styledComponent"
// import styled, { keyframes } from "styled-components"

const slideIn = styledkeyframe`
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`

const NotificationContainer = styledCompoent.div`
  background-color: ${({ type }) => getBackgroundColor(type)};
  color: white;
  padding: 16px;
  border-radius: 8px;
  display: flex;
  align-items: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  animation: ${slideIn} 0.3s ease-out;
  margin-top: 10px;
  min-width: 250px;
`

const CloseButton = styledCompoent.button`
  background: none;
  border: none;
  color: white;
  margin-left: 16px;
  cursor: pointer;
  font-size: 16px;
`

const NotificationText = styledCompoent.span`
  margin-left: 8px;
`

const getBackgroundColor = type => {
  switch (type) {
    case "success":
      return "#4CAF50"
    case "error":
      return "#F44336"
    case "warning":
      return "#FF9800"
    case "info":
      return "#2196F3"
    default:
      return "#2196F3"
  }
}

const getEmoji = type => {
  switch (type) {
    case "success":
      return "✅"
    case "error":
      return "❌"
    case "warning":
      return "⚠️"
    case "info":
      return "ℹ️"
    default:
      return "ℹ️"
  }
}

const Notification = ({ type = "info", message, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(onClose, 3000)
    return () => clearTimeout(timer)
  }, [onClose])

  return (
    <NotificationContainer type={type}>
      <span>{getEmoji(type)}</span>
      <NotificationText>{message}</NotificationText>
      <CloseButton onClick={onClose}>✖️</CloseButton>
    </NotificationContainer>
  )
}

export default Notification

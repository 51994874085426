import React from "../../hooks/reactImport"
import { Suspense } from "../../hooks/stateHooks"
// import { Helmet } from "react-helmet"
// import { Script, ScriptStrategy } from "gatsby"

//  import "../../../static/bootstrap-icons-1.2.1/font/bootstrap-icons.css"

export const getWebFontUris = availbleFonFamily => {
  let fontMap = {}
  if (!availbleFonFamily) return

  availbleFonFamily.forEach(f => {
    let temp = f?.split("/")
    if (fontMap[temp[0]] != null) {
      fontMap[temp[0]].push(f)
    } else {
      fontMap[temp[0]] = []
      fontMap[temp[0]].push(f)
    }
  })

  let rslt = []
  Object.keys(fontMap).forEach(f => {
    let font = new Set()
    let fontWt = new Set()
    let fontWtItalic = new Set()
    let fontSubs = new Set()
    let stle = new Set()
    fontMap[f].forEach(el => {
      let temp = el?.split("/")
      font.add(temp[0])
      fontWt.add(temp[1])
      fontWtItalic.add(temp[1] + "italic")
      fontSubs.add(temp[2])
      stle.add(temp[3])
    })
    rslt.push(
      `"${
        f + ":" + [...fontWt] + "," + [...fontWtItalic] + ":" + [...fontSubs]
      }"`
    )
  })

  return rslt
}

export default function PrimeAgileHelmet({ pageContext, LayoutCSS, pageCss }) {
  const Helmet = React.lazy(() =>
    import("react-helmet").then(module => {
      return {
        default: module.Helmet,
      }
    })
  )
  const websiteCss = pageContext.websiteCSS
  const layoutSetCss = pageContext.layoutSetCss
  const websiteJavascript = pageContext.websiteJavascript
  const jsonLd = pageContext.jsonLd
  const favicon = pageContext.favicon
  const title = pageContext.title
  const fontScheme = pageContext.fontScheme
  const googleAnalyticsCode = pageContext.googleAnalyticsCode
  const metaTitle = pageContext.metaTitle
  const pathname = pageContext.url
  const metaDescription = pageContext.description
  const coverImage = pageContext.coverImageUrl
  const locales = pageContext.locales

  const protocol = window.location.protocol
  function isUrlValid(url) {
    // validate if url is correct using regex
    const regex =
      /(http|https):\/\/(\w+:{0,1}\w*)?(\S+)(:[0-9]+)?(\/|\/([\w#!:.?+=&%!]))?/
    if (regex.test(url)) {
      return true
    }
    return false
  }

  const fonts =
    fontScheme?.availableFontFamily &&
    getWebFontUris(fontScheme?.availableFontFamily)

  const googleTag =
    googleAnalyticsCode &&
    googleAnalyticsCode !== "string" &&
    googleAnalyticsCode.split(",")

  React.useEffect(() => {
    if (process.env.GATSBY_BUILDSITE === "diamondskiesgroup.com") return
    let timer
    if (googleTag && googleTag[0]) {
      timer = setTimeout(() => {
        const googleAnalyticsCodeScriptTag = document.createElement("script")
        const googleAnalyticsCodeScript = document.createElement("script")
        googleAnalyticsCodeScript.textContent = `
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${googleTag[0]}');`
        googleAnalyticsCodeScriptTag.src = `https://www.googletagmanager.com/gtag/js?id=${googleTag[0]}`
        googleAnalyticsCodeScriptTag.defer = true
        document.head.appendChild(googleAnalyticsCodeScriptTag)
        document.head.appendChild(googleAnalyticsCodeScript)
        googleAnalyticsCodeScript.async = true
      }, 10000)
    }

    return () => {
      clearTimeout(timer)
    }
  }, [])

  // React.useEffect(() => {
  //   const script = document.createElement("script")
  //   script.src = websiteJavascript
  //   script.async = true
  //   document.body.appendChild(script)
  //   return () => {
  //     try {
  //       document.body.removeChild(script)
  //     } catch (error) {}
  //   }
  // }, [])

  const canonical = pathname
    ? `${process.env.GATSBY_BUILDSITE}${pathname}`
    : null

  React.useEffect(() => {
    var image = document.createElement("meta")
    image.setAttribute("property", "og:image")
    image.content = coverImage
    const head = document.getElementsByTagName("head")[0]
    head.insertBefore(image, head.firstChild)
  }, [])

  return (
    <Suspense fallback={<div />}>
      <Helmet
        htmlAttributes={{
          lang: "en-US",
        }}
        title={pageContext.title}
        titleTemplate={`%s | ${process.env.GATSBY_BUILDSITE.replace(
          ".com",
          ""
        )}`}
        link={
          canonical
            ? [
                {
                  rel: "canonical",
                  href: canonical,
                },
              ]
            : []
        }
        meta={[
          {
            name: `description`,
            content: metaDescription,
          },
          {
            name: "keywords",
          },
          {
            property: `og:title`,
            content: metaTitle?.phrase || title,
          },
          {
            property: `og:image:url`,
            content: coverImage,
          },
          {
            property: `og:description`,
            content: metaDescription,
          },
          {
            property: `og:type`,
            content: `website`,
          },
          {
            name: `twitter:creator`,
            content: process.env.GATSBY_BUILDSITE.replace(".com", ""),
          },
          {
            name: `twitter:title`,
            content: metaTitle?.phrase || title,
          },
          {
            name: `twitter:description`,
            content: metaDescription,
          },
          {
            name: `twitter:image`,
            content: coverImage,
          },
        ].concat([
          {
            name: "twitter:card",
            content: "summary",
          },
        ])}
      >
        {/* {googleTag && googleTag[0] && (
        <script
          src={`https://www.googletagmanager.com/gtag/js?id=${googleTag[0]}`}
          async
        />
      )} */}

        <title>{title}</title>
        <meta property="og:image" content={coverImage} />

        <meta property="og:image:height" content="630"></meta>
        <meta property="og:image:width" content="1200"></meta>
        <meta
          property="og:image:alt"
          content={pageContext?.CoverImagePicture?.coverPictureAltText}
        ></meta>

        <meta name="description" content={metaDescription} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content={title} />
        <meta property="og:description" content={metaDescription} />
        <meta property="og:type" content={"website"} />
        <meta property="og:url" content={`https://www.demo.primeagile.com`} />
        {pageContext?.websiteCSS && (
          <link
            rel="stylesheet preconnect"
            data-primeagile-website
            type="text/css"
            href={pageContext?.websiteCSS}
            fetchpriority="high"
            as="style"
            defer
          />
        )}
        {LayoutCSS && (
          <link
            rel="stylesheet preconnect"
            data-primeagile-website
            type="text/css"
            href={LayoutCSS}
            fetchpriority="high"
            as="style"
            defer
          />
        )}
        {pageCss && (
          <link
            rel="stylesheet preconnect"
            data-primeagile-website
            type="text/css"
            href={pageCss}
            fetchpriority="high"
            as="style"
            defer
          />
        )}
        {process.env.GATSBY_BUILDSITE === "ibcscorp.com" && (
          <link
            rel="preload"
            fetchpriority="high"
            as="image"
            href="https://images.primeagile.com/ibcscorp_com/image/32051/banner-3.webp"
            type="image/webp"
          />
        )}

        {/* {process.env.GATSBY_BUILDSITE === "ibcscorp.com" && (
          <link
            rel="preload"
            fetchpriority="high"
            as="image"
            href="      https://images.primeagile.com/ibcscorp_com/image/29310/banner-img1.webp"
            type="image/webp"
          />
        )} */}

        {process.env.GATSBY_BUILDSITE === "ibcscorp.com" && (
          <link
            rel="preload"
            fetchpriority="high"
            as="image"
            href="https://images.primeagile.com/ibcscorp_com/image/32052/banner--3.webp"
            type="image/webp"
          />
        )}

        {process.env.GATSBY_BUILDSITE === "ibcscorp.com" && (
          <link
            rel="preload"
            fetchpriority="high"
            as="image"
            href="https://images.primeagile.com/ibcscorp_com/image/29311/bg.webp"
            type="image/webp"
          />
        )}

        {process.env.GATSBY_BUILDSITE === "ibcscorp.com" && (
          <link
            rel="preload"
            fetchpriority="high"
            as="image"
            href="https://images.primeagile.com/ibcscorp_com/image/29313/bg-right.webp"
            type="image/webp"
          />
        )}
        <link
          rel="preconnect"
          href="https://images.primeagile.com"
          crossorigin
        />
        <link rel="preconnect" href="https://s3.amazonaws.com" crossorigin />
        {/* {pathname === "/performance-test/" && (
          <style>
            {`
            
                #primeagile__18117{
                   background: white !important;
                  }
  
                  .homepage-2024-banner {
                    background: unset !important;
                  }
  
                  #primeagile__37330 img {
                    display: none !important;
                  }
                  
                
                    #primeagile__37322 {
                      display: none !important;
                    }
  
                  #primeagile__37339 {
                  display: none !important;
                  }
  
                  #primeagile__37331 {
                                  display: none !important;
  
                  }
  
                  #primeagile__37355,
                  #primeagile__37351,
                  #primeagile__37357,
                  #primeagile__37359,
                  #primeagile__37374,
                  #primeagile__37375,
                  #primeagile__37376,
                  #primeagile__37377,
                  #primeagile__37378,
                  #primeagile__37379,
                  #primeagile__37380,
                  #primeagile__37381,
                  #primeagile__37382,
                  #primeagile__37383,
                  #primeagile__37384,
                  #primeagile__37385,
                  #primeagile__37386,
                  #primeagile__37373 {
                      display: none !important;
  
                  }
              
              
              `}
          </style>
        )} */}

        {/* {googleTag &&
        googleAnalyticsCode?.split(",").map(code => {
          return (
            <script
              key={code}
              src={`https://www.googletagmanager.com/gtag/js?id=${code}`}
              async
            />
          )
        })} */}

        <meta charSet="utf-8" />
        <meta
          name="keywords"
          content={
            pageContext?.keyPhrase?.length > 0 &&
            pageContext?.keyPhrase?.map(phrase => phrase.phrase)?.join(",")
          }
        />

        <link
          rel="alternate"
          hreflang="x-default"
          href={`${protocol}//${process.env.GATSBY_BUILDSITE}${pathname}`}
        />

        {/* <meta name="description" content={pageContext.description} />
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      {(metaTitle && <meta property="og:title" content={metaTitle.phrase} />) ||
        (title && <meta property="og:title" content={title} />)}
      <meta property="og:description" content={pageContext.description} />
      <meta property="og:type" content={"website"} />
      <meta
        property="og:url"
        content={`https://www.${process.env.GATSBY_BUILDSITE}`}
      /> */}

        <script type="application/ld+json" async>
          {jsonLd ? `${JSON.stringify(jsonLd)}` : ""}
        </script>

        <link rel="icon" type="image/x-icon" href={favicon} />
        {isUrlValid(favicon) ? (
          <link rel="shortcut icon" type="image/jpg" href={favicon} />
        ) : (
          "https://www.admin.primeagile.com/favicon.ico"
        )}
      </Helmet>
    </Suspense>
  )
}

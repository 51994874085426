import React from "../../../../hooks/reactImport" 
import Notification from "./Notification"
import { styledCompoent } from "../../../../styledComponent"
import { useCallback, useState } from "../../../../hooks/stateHooks"

const NotificationContainer = styledCompoent.div`
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 999999;
`

const NotificationManager = React.forwardRef((props, ref) => {
  const [notifications, setNotifications] = useState([])

  const addNotification = useCallback((type, message) => {
    setNotifications(prevNotifications => [
      ...prevNotifications,
      { type, message, id: Date.now() },
    ])
  }, [])

  const removeNotification = useCallback(id => {
    setNotifications(prevNotifications =>
      prevNotifications.filter(notification => notification.id !== id)
    )
  }, [])

  React.useImperativeHandle(ref, () => ({
    notify: (type, message) => addNotification(type, message),
  }))

  return (
    <NotificationContainer>
      {notifications.map(notification => (
        <Notification
          key={notification.id}
          type={notification.type}
          message={notification.message}
          onClose={() => removeNotification(notification.id)}
        />
      ))}
    </NotificationContainer>
  )
})

export default NotificationManager
